import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import { AlertCircle } from '@styled-icons/evaicons-solid/AlertCircle'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { PostCard, Pagination, Search } from '../components/BlogListTemplate'
import {
  ContentContainer,
  HeadingContainer,
  PostsFoundCounter,
  PostsNotFoundContainer,
} from '../components/BlogListTemplate/styled'
import { useSearchWithPagination } from '../hooks/useSearchWithPagination'

const BlogList = ({ data, pageContext, location }) => {
  const blogListGatsbyPage = data.wordpressWpGatsbyPage.acf
  const settings = data.settings
  const allPosts = data.allPosts.edges
  const currentStaticPosts = data.posts.edges
  const staticTotalPages = pageContext.totalPages
  const staticCurrentPage = pageContext.currentPage

  const {
    currentPage,
    currentPosts,
    totalPages,
    searchQuery,
    filteredPosts,
    handlePageChange,
    handleSearch,
  } = useSearchWithPagination(pageContext.limit, allPosts)

  const postsToRender = !searchQuery ? currentStaticPosts : currentPosts

  const getPagePath = page => {
    return page === 1 ? `/noticias` : `/noticias/${page}`
  }

  return (
    <Layout pageContext={pageContext} location={location}>
      <SEO
        title={`${blogListGatsbyPage.title} | ${settings.brand_name}`}
        description={blogListGatsbyPage.description}
        canonical={blogListGatsbyPage.canonical}
      />

      <ContentContainer>
        <HeadingContainer>
          <h1>O que você deseja saber hoje?</h1>

          <span>
            Bem-vindo ao nosso blog, confira os artigos mais interessantes para cuidar da sua saúde.
          </span>
        </HeadingContainer>

        <div>
          <Search value={searchQuery} onChange={value => handleSearch(value)} />

          {!!searchQuery && !!postsToRender.length && (
            <PostsFoundCounter>
              <strong>{filteredPosts.length}</strong>
              {` resultados encontrados para ${searchQuery}...`}
            </PostsFoundCounter>
          )}
        </div>

        {postsToRender.map(({ node }) => {
          return (
            <PostCard key={node.slug} title={node.title} excerpt={node.excerpt} slug={node.slug} />
          )
        })}

        {totalPages > 1 && (
          <Pagination
            currentPage={!searchQuery ? staticCurrentPage : currentPage}
            totalPages={!searchQuery ? staticTotalPages : totalPages}
            handlePageChange={handlePageChange}
            getPagePath={getPagePath}
            isSearchActive={!!searchQuery}
          />
        )}

        {!!searchQuery && !postsToRender.length && (
          <PostsNotFoundContainer>
            <AlertCircle size={24} />
            <h3>Título não encontrado</h3>
            <span>Nenhuma descrição foi encontrada</span>
          </PostsNotFoundContainer>
        )}
      </ContentContainer>
    </Layout>
  )
}

BlogList.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default BlogList

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    wordpressWpGatsbyPage(slug: { eq: "noticias" }) {
      acf {
        title
        description
        canonical
      }
    }
    settings: wordpressAltaThemeSettings {
      brand_name
      brand_url
    }
    posts: allWordpressPost(sort: { fields: date, order: DESC }, skip: $skip, limit: $limit) {
      edges {
        node {
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allPosts: allWordpressPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
