import { useMemo, useState } from 'react'

import { useDebounce } from './useDebounce'

export const useSearchWithPagination = (postsPerPage, allPosts) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const debouncedSearchQuery = useDebounce(searchQuery, 300)

  const filteredPosts = useMemo(() => {
    if (!debouncedSearchQuery) return allPosts

    return allPosts.filter(
      ({ node }) =>
        node.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        node.excerpt.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [allPosts, debouncedSearchQuery])

  const currentPosts = useMemo(() => {
    const startIndex = (currentPage - 1) * postsPerPage
    const endIndex = startIndex + postsPerPage
    return filteredPosts.slice(startIndex, endIndex)
  }, [filteredPosts, currentPage, postsPerPage])

  const totalPages = Math.ceil(filteredPosts.length / postsPerPage)

  const handleSearch = query => {
    setSearchQuery(query)
    setCurrentPage(1)
  }

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber)
  }

  return {
    searchQuery,
    filteredPosts,
    currentPosts,
    currentPage,
    totalPages,
    handleSearch,
    handlePageChange,
  }
}
