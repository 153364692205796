export default function usePagination(totalPages, currentPage) {
  const dotts = '...'

  const generatePages = (length, inc = 1) => Array.from({ length }, (_, i) => i + inc)

  // -> 1 2 3 4 5 6 7
  if (totalPages <= 7) {
    return [...generatePages(totalPages)]
  }

  // -> 1* 2* 3 ... 8 9 10
  if (currentPage < 3) {
    return [...generatePages(3), dotts, ...generatePages(3, totalPages - 2)]
  }

  // -> 1 2 3 ... 8 9* 10*
  if (currentPage > totalPages - 2) {
    return [...generatePages(3), dotts, ...generatePages(3, totalPages - 2)]
  }

  // -> 1 2 3* 4* 5* 6* 7 8
  if (currentPage >= totalPages - 5 && currentPage < 7) {
    return [...generatePages(totalPages)]
  }

  // -> 1 2 3 ... 27 28* 29 30
  if (currentPage === totalPages - 2) {
    return [...generatePages(3), dotts, ...generatePages(4, totalPages - 3)]
  }

  // -> 1 2 3 ... 26 27* 28 29 30
  if (currentPage === totalPages - 3) {
    return [...generatePages(3), dotts, ...generatePages(5, totalPages - 4)]
  }

  // -> 1 2 3 ... 25 26* 27 28 29 30
  if (currentPage === totalPages - 4) {
    return [...generatePages(3), dotts, ...generatePages(6, totalPages - 5)]
  }

  // -> 1 2 3 ... 24 25* 26 27 28 29 30
  if (currentPage === totalPages - 5) {
    return [...generatePages(3), dotts, ...generatePages(7, totalPages - 6)]
  }

  // -> 1 2 3* 4 ... 28 29 30
  if (currentPage === 3) {
    return [...generatePages(4), dotts, ...generatePages(3, totalPages - 2)]
  }

  // -> 1 2 3 4* 5 ... 28 29 30
  if (currentPage === 4) {
    return [...generatePages(5), dotts, ...generatePages(3, totalPages - 2)]
  }

  // -> 1 2 3 4 5* 6... 28 29 30
  if (currentPage === 5) {
    return [...generatePages(6), dotts, ...generatePages(3, totalPages - 2)]
  }

  // -> 1 2 3 4 5 6* 7 ... 28 29 30
  if (currentPage === 6) {
    return [...generatePages(7), dotts, ...generatePages(3, totalPages - 2)]
  }

  // -> 1 2 3 ... 14 15* 16 ... 28 29 30
  return [
    ...generatePages(3),
    dotts,
    ...generatePages(3, currentPage - 1),
    dotts,
    ...generatePages(3, totalPages - 2),
  ]
}
