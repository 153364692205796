import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@styled-icons/material-rounded'

import usePagination from '../../hooks/usePagination'

import * as S from './styled'

const dotts = '...'

const Pagination = ({ totalPages, currentPage, handlePageChange, getPagePath, isSearchActive }) => {
  const pages = usePagination(totalPages, currentPage)

  let hasDottsWithPreviousLink = false

  const handleDottsLink = dottsIndex => {
    if (hasDottsWithPreviousLink) return currentPage + 2

    if (dottsIndex + 1 <= currentPage) {
      hasDottsWithPreviousLink = true
      return currentPage - 2
    }

    return currentPage + 2
  }

  return (
    <S.PaginationContainer>
      <ul>
        <S.PaginationItem isDisable={currentPage === 1}>
          {isSearchActive ? (
            <button onClick={() => handlePageChange(currentPage - 1)}>
              <KeyboardArrowLeft />
            </button>
          ) : (
            <Link
              to={getPagePath(currentPage - 1)}
              aria-label="Voltar para página anterior"
              tabIndex={0}
              rel="prev"
              data-testid="pagination-previous-link"
            >
              <KeyboardArrowLeft />
            </Link>
          )}
        </S.PaginationItem>

        {pages.map((pageNumber, i) =>
          pageNumber === dotts ? (
            <S.PaginationItem key={`${pageNumber}-${i}`}>
              {isSearchActive ? (
                <button onClick={() => handlePageChange(handleDottsLink(i))}>{pageNumber}</button>
              ) : (
                <Link to={getPagePath(handleDottsLink(i))} tabIndex={0}>
                  {pageNumber}
                </Link>
              )}
            </S.PaginationItem>
          ) : (
            <S.PaginationItem key={`${pageNumber}-${i}`} isActive={currentPage === pageNumber}>
              {isSearchActive ? (
                <button onClick={() => handlePageChange(pageNumber)}>{pageNumber}</button>
              ) : (
                <Link
                  to={getPagePath(pageNumber)}
                  aria-label={`Clique para ir para a página ${pageNumber}`}
                  tabIndex={0}
                >
                  {pageNumber}
                </Link>
              )}
            </S.PaginationItem>
          )
        )}

        <S.PaginationItem isDisable={currentPage === totalPages}>
          {isSearchActive ? (
            <button onClick={() => handlePageChange(currentPage + 1)}>
              <KeyboardArrowRight />
            </button>
          ) : (
            <Link
              to={getPagePath(currentPage + 1)}
              aria-label="Avançar para próxima página"
              tabIndex={0}
              rel="next"
              data-testid="pagination-next-link"
            >
              <KeyboardArrowRight />
            </Link>
          )}
        </S.PaginationItem>
      </ul>
    </S.PaginationContainer>
  )
}

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  getPagePath: PropTypes.func.isRequired,
  isSearchActive: PropTypes.bool.isRequired,
}

export default Pagination
