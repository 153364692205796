import React from 'react'
import PropTypes from 'prop-types'

import { Search as SearchIcon } from '@styled-icons/evaicons-solid/Search'
import { Close } from '@styled-icons/material-rounded'

import * as S from './styled'

const Search = ({ value, onChange, placeholder = 'Pesquisar' }) => {
  return (
    <S.SearchContainer>
      <S.SearchLabel>
        <SearchIcon size={24} />

        <S.SearchInput
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
        />

        {value && (
          <S.SearchClear aria-label="Resetar" onClick={() => onChange('')}>
            <Close size={24} />
          </S.SearchClear>
        )}
      </S.SearchLabel>
    </S.SearchContainer>
  )
}

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default Search
