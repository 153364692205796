import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const PostCard = ({ title, excerpt, slug }) => {
  return (
    <S.PostCardContainer>
      <a href={`/saude/${slug}`}>
        <h2>{title}</h2>
        {!!excerpt && <div dangerouslySetInnerHTML={{ __html: excerpt }} />}
      </a>
    </S.PostCardContainer>
  )
}

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  slug: PropTypes.string.isRequired,
}

export default PostCard
