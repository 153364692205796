import styled from 'styled-components'
import media from 'styled-media-query'

export const ContentContainer = styled.main`
  max-width: 646px;
  margin: 0px auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${media.lessThan('medium')`
    gap: 24px;
  `}
`

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid rgba(22, 22, 22, 0.24);
  padding-bottom: 16px;

  h1 {
    font-size: 3.6rem;
    margin-top: 32px;
    margin-bottom: 0;
    color: ${props => props.theme.colors.primary};

    ${media.lessThan('medium')`
      font-size: 2.4rem;
    `}
  }

  span {
    font-size: 1.4rem;
  }
`

export const PostCardContainer = styled.div`
  a {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid rgba(22, 22, 22, 0.24);
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }

  h2,
  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  h2 {
    font-size: 2rem;
    width: 100%;
    color: ${props => props.theme.colors.primary};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;

    ${media.lessThan('medium')`
      font-size: 1.6rem;
    `}
  }

  p {
    font-size: 1.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    gap: 8px;
    padding: 0;
  }
`

export const PaginationItem = styled.li`
  list-style: none;

  button,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 1.6rem;
    color: ${props => (props.isActive ? '#fefefe' : '#666666')};
    background: ${props => (props.isActive ? props.theme.colors.primary : 'transparent')};
    pointer-events: ${props => (props.isDisable ? 'none' : 'all')};
    opacity: ${props => (props.isDisable ? '0.24' : '1')};

    svg {
      width: 32px;
      height: 32px;
    }
  }

  button {
    border: 0;
    outline: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 0 12px;
  }
`

export const SearchContainer = styled.div`
  margin-bottom: 2px;
  display: flex;
  background-color: rgba(22, 22, 22, 0.04);
  border-bottom: 1px solid rgb(102, 102, 102);
  align-items: center;
  padding: 2px 16px;
  gap: 16px;
`

export const SearchLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`

export const SearchInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  display: block;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  font-family: 'Dasa Sans', Arial, Lato, sans-serif;
  outline: none;
  border: none;
  background-color: transparent;
  border-radius: 4px 4px 0px 0px;
  color: rgb(102, 102, 102);
  height: 54px;
  font-size: 1.6rem;
`

export const SearchClear = styled.button`
  background: none;
  outline: none;
  border: 0;
  cursor: pointer;
`

export const PostsFoundCounter = styled.div`
  font-size: 1.4rem;
  margin-top: 8px;
`

export const PostsNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 2rem;
  }

  span {
    font-size: 1.6rem;
  }
`
